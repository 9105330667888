<template>
  <v-btn
    v-on="$listeners"
    v-bind="$attrs"
    color="secondary"
    outlined rounded small
    class="mx-5 back-button">
    <cepp-icon icon="arrow-left-circle" size="12" class="mr-2" />
    Back
  </v-btn>
</template>

<script>
export default {
  name: 'dialog-back-button'
};
</script>

<style lang="scss">
.back-button {
  width: max-content;
  transition: all 0.3s;

  .v-btn__content {
    transition: all 0.3s;
  }

  &:hover {
    .v-btn__content {
      color: #fff;
    }

    background: $secondary-color;
  }

  .v-icon {
    transition: none;
  }
}
</style>
