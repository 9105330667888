<template>
  <validation-provider
    v-slot="{ errors }"
    ref="validator"
    mode="eager"
    :name="$attrs.name"
    :rules="rules"
    tag="div"
    class="d-flex field-wrapper align-baseline">
    <div>
      <v-textarea
        ref="textArea"
        v-model="innerValue"
        @focus="showMessage = $event"
        @blur="showMessage = false"
        v-on="$listeners"
        :disabled="disabled"
        v-bind="$attrs"
        rows="3"
        class="m-0 p-0" />
      <p
        v-if="errors.length"
        class="m-0 ml-3 p-0 error-message">
        <slot name="error-message">{{ errors[0] }}</slot>
      </p>
      <p class="m-0 ml-3 p-0 hint">
        <span v-if="showMessage">
          <slot name="message"></slot>
        </span>
      </p>
    </div>
    <span @click="enableEditing">
      <cepp-icon
        icon="pencil"
        size="18"
        :color="disabled ? 'grey' : 'attention'"
        class="ml-8 pencil" />
    </span>
  </validation-provider>
</template>

<script>
export default {
  name: 'cepp-text-area',
  props: {
    rules: { type: [Object, String], default: '' },
    value: { type: String, default: '' },
    disabled: { type: Boolean, default: false }
  },
  data: () => ({ showMessage: false }),
  computed: {
    innerValue: {
      get() { return this.value; },
      set(value) { this.$emit('input', value); }
    }
  },
  methods: {
    enableEditing() {
      this.$nextTick(() => this.$refs.textArea.$el.querySelector('textarea').focus());
    }
  }
};
</script>

<style lang="scss" scoped>
.field-wrapper {
  max-width: 510px;
  margin-bottom: 45px;

  & > div {
    width: 100%;
  }
}

.error-message {
  color: $not-so-red;
  font-size: 15px;
  line-height: 18px;
  font-style: italic;
}

.pencil {
  cursor: pointer;
}

.hint {
  min-height: 18px;
  color: $label-color;
  font-size: 15px;
  line-height: 18px;
  font-style: italic;

  a {
    color: $label-color;
    text-decoration: none;
  }
}

::v-deep {
  .v-input.v-text-field--outlined .v-input__control {
    .v-text-field__details {
      display: none;
    }

    .v-label:not(.v-label--active) {
      top: 13px;
    }

    .v-input__slot {
      min-height: 45px;
    }
  }
}
</style>
