import App from './App.vue';
import { Auth0Plugin } from '@/plugins/auth0';
import CeppBtn from '@/components/CeppBtn';
import CeppIcon from '@/components/CeppIcon';
import router from './router';
import VeeValidate from '@/plugins/validation';
import Vue from 'vue';
import VueHotkey from 'v-hotkey';
import vuetify from '@/plugins/vuetify';

Vue.use(Auth0Plugin, {
  domain: process.env.AUTH0_CUSTOM_DOMAIN || process.env.AUTH0_DOMAIN,
  clientId: process.env.AUTH0_CLIENT_ID,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});
Vue.use(VueHotkey);
Vue.use(VeeValidate);
Vue.config.productionTip = false;

Vue.component('cepp-icon', CeppIcon);
Vue.component('cepp-btn', CeppBtn);

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app');
