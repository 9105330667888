import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

const colors = {
  green: '#48a441',
  lightYellow: '#ffce0a',
  tiber: '#062d3a',
  fauxSpace: '#21225b',
  fauxIndigo: '#343f79',
  red: '#b23f3f',
  grey73: '#b9b9b9',
  flamePea: '#de5a3a',
  orangina: '#bf482b'
};

export default new Vuetify({
  iconfont: 'mdi',
  theme: {
    themes: {
      light: {
        primary: colors.fauxIndigo,
        secondary: colors.tiber,
        accent: colors.green,
        error: colors.red,
        warning: colors.lightYellow,
        info: colors.grey73,
        anchor: colors.fauxSpace,
        highlight: colors.flamePea,
        attention: colors.orangina
      }
    }
  }
});
