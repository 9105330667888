<template>
  <v-app-bar
    color="white"
    app
    flat
    dark
    height="75"
    extension-height="50"
    class="app-bar">
    <v-toolbar-title class="pl-8">
      <div class="d-flex align-center">
        <img src="/assets/logo-blue.png" alt="appr logo" class="logo-img">
      </div>
    </v-toolbar-title>
    <v-spacer />
    <div class="d-flex align-center menu-container pr-8">
      <a
        :href="navItem.redirectTo"
        class="navbar-link d-flex align-center align-self-stretch font-weight-bold px-6">
        {{ navItem.title }}
        <cepp-icon icon="open" size="12" class="ml-2" fill />
      </a>
      <dropdown-menu />
    </div>
  </v-app-bar>
</template>

<script>
import DropdownMenu from './DropdownMenu';

const navItems = {
  community: {
    url: process.env.COMMUNITY_URL,
    title: 'APPR COMMUNITY'
  },
  learning: {
    url: process.env.PLATFORM_URL,
    title: 'APPR LEARNING'
  },
  website: {
    url: process.env.WEBSITE_URL,
    title: 'ADVANCINGPRETRIAL.ORG'
  }
};

export default {
  name: 'main-navbar',
  computed: {
    navItem: ({ $route }) => {
      const source = navItems[$route.query.source] ?? navItems.website;
      return {
        ...source,
        redirectTo: $route.query.redirectTo ?? source.url
      };
    }
  },
  components: { DropdownMenu }
};
</script>

<style lang="scss" scoped>
.disabled {
  pointer-events: none;
}

.logo-img {
  height: 30px;
}

.app-bar {
  @include z-index(main-nav);

  box-shadow: 0 2px 2px rgba(77,77,77,0.1) !important;
}

.menu-container {
  height: 100%;
  color: $grey-10;
  background-color: $grey-90;

  a, .v-icon {
    color: inherit;
  }
}

$navbar-link-colors: (
  "default": $black,
  "hover": $accent-color
);

@mixin set-navbar-link-colors($state) {
  color: map-get($navbar-link-colors, $state);

  ::v-deep .svg-icon {
    color: currentColor;

    path {
      fill: map-get($navbar-link-colors, $state);
      transition: fill 0.3s;
    }
  }
}

.navbar-link {
  font-size: 0.8125rem;
  text-decoration: none;
  transition: all 0.3s;
  @include set-navbar-link-colors(default);

  &:hover, &:focus {
    outline: 0;
    @include set-navbar-link-colors(hover);
  }
}

::v-deep .v-toolbar__extension {
  padding: 0;
  background: $secondary-color;
}

::v-deep .v-toolbar__content {
  padding: 0;
}
</style>
