<template functional>
  <div class="page-header" :class="{ 'pa-8': !props.slim }">
    <div :class="{ extended: props.extended }" class="content-wrapper">
      <div class="title-wrapper mb-10">
        <div class="icon-wrapper">
          <slot name="title-icon"></slot>
        </div>
        <h1>{{ props.title }}</h1>
      </div>
      <div class="slot-wrapper">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'page-header',
  props: {
    title: { type: String, required: true },
    slim: { type: Boolean, default: false },
    extended: { type: Boolean, default: false }
  }
};
</script>

<style lang="scss" scoped>
.page-header {
  color: $white;
  background-color: $primary-color;

  .content-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 56px;
    justify-content: center;
    padding: 20px 12px;

    &.extended {
      min-height: 113px;
    }
  }

  .title-wrapper {
    display: flex;
    flex-direction: row;

    h1 {
      margin: 0;
      font-family: $primary-font;
      color: $text-color-light;
      font-size: 2.3rem;
      font-weight: 600;
      line-height: 2.5rem;
    }
  }

  .slot-wrapper {
    font-size: 1.3rem;
  }
}
</style>
