<template>
  <v-container
    fluid
    class="align-start fill-height">
    <navbar class="app-bar" />
    <v-main class="mx-auto">
      <router-view />
    </v-main>
    <cepp-footer />
  </v-container>
</template>

<script>
import CeppFooter from '@/components/CeppFooter';
import Navbar from '@/components/Navbar';

export default {
  name: 'cepp-profile',
  components: { Navbar, CeppFooter }
};
</script>

<style lang="scss" scoped>
.app-bar {
  flex: 0;
}

.container {
  padding: 0;
  flex-flow: column nowrap;
}

.v-main {
  width: 100%;
  flex: 1;

  ::v-deep .v-main__wrap {
    display: flex;
    flex-direction: column;
  }
}
</style>
