<template>
  <v-app id="app">
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: 'app'
};
</script>

<style lang="scss">
@import "./stylesheets/main";

* {
  box-sizing: border-box;
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.content-wrapper {
  margin: auto;
  padding: 0 40px;

  @media (min-width: 1264px) {
    max-width: $app-measure;
    padding: 0 12px;
  }
}

.v-application {
  font-family: $primary-font !important;
}
</style>
