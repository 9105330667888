<template>
  <div class="user-profile-wrapper">
    <page-header title="Account Settings">
      <template #content>
        {{
          isFirstLogin
            ? 'Please verify and confirm your profile information before continuing.'
            : 'Update your account settings here.'
        }}
      </template>
    </page-header>
    <validation-observer
      v-slot="{ handleSubmit }"
      ref="validator"
      tag="form"
      class="content-wrapper d-flex flex-column align-items-center py-10">
      <v-row>
        <v-col class="align" md="3" sm="5">
          <sidebar @updated="fetchUser" :profile="profile" />
        </v-col>
        <v-col md="9" sm="7">
          <h1>Profile</h1>
          <p class="py-4 description">Enter your information and then click "Save."</p>
          <cepp-text-field
            v-model="profile.given_name"
            rules="required|min:1|max:60|names"
            error-message="Please fill out this field."
            label="First Name"
            name="First Name"
            outlined />
          <cepp-text-field
            v-model="profile.family_name"
            rules="required|min:1|max:60|names"
            error-message="Please fill out this field."
            name="Last Name"
            label="Last Name"
            outlined />
          <cepp-text-field
            v-model="profile.user_metadata.organization"
            rules="required|max:60"
            name="organization"
            label="Organization"
            outlined />
          <cepp-text-field
            ref="emailField"
            v-model="profile.email"
            name="email"
            label="Email"
            :validate="false"
            disabled outlined>
            <template #message>
              Please contact the
              <a href="https://advancingpretrial.org/appr/help/">Help Desk</a>
              to request an email address change.
            </template>
          </cepp-text-field>
          <cepp-text-field
            v-model="profile.user_metadata.job_title"
            rules="max:60"
            name="jobTitle"
            label="Job Title"
            outlined />
          <cepp-text-field
            ref="zipCode"
            v-model="profile.user_metadata.zip_code"
            rules="zipcode"
            error-message="Enter a valid US zip code."
            label="Zip Code"
            name="zip code"
            outlined />
          <cepp-text-field
            v-model="profile.user_metadata.county"
            rules="max:60"
            label="County"
            name="county"
            outlined />
          <cepp-text-field
            v-model="profile.user_metadata.city"
            rules="max:60"
            label="City"
            name="city"
            outlined />
          <cepp-autocomplete
            v-model="profile.user_metadata.state"
            :items="usaStateNames"
            label="State"
            name="state"
            outlined clearable />
          <cepp-text-area
            v-model="profile.user_metadata.bio"
            rules="max:2500"
            label="Bio"
            name="bio"
            outlined>
            <template #message>
              {{
                profile.user_metadata.is_learning
                  ? 'Your bio will only appear on APPR Community and APPR Learning.'
                  : 'Your bio will only appear on APPR Community.'
              }}
            </template>
          </cepp-text-area>
          <cepp-btn
            @click="handleSubmit(save)"
            :loading="saving"
            :disabled="saving"
            color="accent"
            class="save">
            Save
            <template #icon>
              <cepp-icon icon="chevron-right" fill />
            </template>
          </cepp-btn>
        </v-col>
      </v-row>
    </validation-observer>
    <cepp-dialog
      v-on="$listeners"
      @update:show="saveConfirmationDialog = false"
      v-bind="$attrs"
      max-width="480"
      :show="saveConfirmationDialog"
      outlined>
      <div class="align-center d-flex flex-column">
        <template v-if="saveSuccessful">
          <cepp-icon icon="check-circle-outline" size="55" color="accent" fill />
          <p class="message-title font-weight-bold primary--text mt-4">{{ saveMessage }}</p>
        </template>
        <template v-else>
          <cepp-icon icon="times-circle" size="55" color="error" fill />
          <p class="message-title text-center font-weight-bold primary--text mt-4">
            {{ saveMessage }}
          </p>
          <p class="text-center">
            Please try saving again. If you still have trouble,<br>
            please contact the <a href="https://advancingpretrial.org/appr/help/">Help Desk</a>.
          </p>
        </template>
      </div>
    </cepp-dialog>
  </div>
</template>

<script>
import { actions, getters, mutations } from '@/store.js';
import { cloneDeep, isEqual } from 'lodash-es';
import CeppAutocomplete from '@/components/CeppAutocomplete';
import CeppDialog from '@/components/Dialog';
import CeppTextArea from '@/components/CeppTextArea';
import CeppTextField from '@/components/CeppTextField';
import PageHeader from '@/components/PageHeader';
import Sidebar from './Sidebar';
import { UsaStates } from 'usa-states';

const USA_STATE_NAMES = new UsaStates().arrayOf('names');

const getEmptyProfile = () => ({
  family_name: '',
  give_name: '',
  user_metadata: {
    zip_code: '',
    county: '',
    city: '',
    state: '',
    bio: '',
    job_title: ''
  },
  picture: '',
  email: ''
});

export default {
  name: 'user-profile',
  props: {
    isFirstLogin: { type: Boolean, required: true }
  },
  data: () => ({
    saving: false,
    saveSuccessful: false,
    saveConfirmationDialog: false,
    saveMessage: '',
    profile: getEmptyProfile(),
    error: null,
    isRemoveConfirmOpen: false,
    showAvatarDialog: false
  }),
  computed: {
    user: () => getters.user(),
    usaStateNames: () => USA_STATE_NAMES,
    hasChanges: ({ profile, fetchedProfile }) => !isEqual(profile, fetchedProfile),
    showRemoveButton: ({ profile, avatar }) => {
      return (profile.picture && !profile.picture.includes('ui-avatars')) || avatar;
    }
  },
  methods: {
    ...mutations,
    async fetchUser() {
      return actions.fetchUser(this.user.email);
    },
    save() {
      this.saving = true;
      return actions.updateUser(this.profile)
        .then(user => {
          this.saveSuccessful = true;
          this.saveConfirmationDialog = true;
          this.saveMessage = 'Profile updated successfully!';
        })
        .catch(() => {
          this.saveSuccessful = false;
          this.saveConfirmationDialog = true;
          this.saveMessage = 'Something went wrong.';
        })
        .finally(() => {
          this.saving = false;
          this.$refs.emailField.hideMessage();
        });
    }
  },
  watch: {
    user: {
      handler() {
        this.profile = cloneDeep(this.user);
        this.$nextTick().then(() => {
          this.$refs.validator.reset();
        });
      },
      immediate: true,
      deep: true
    }
  },
  components: {
    CeppDialog,
    CeppAutocomplete,
    CeppTextArea,
    CeppTextField,
    PageHeader,
    Sidebar
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-input {
    min-width: 100%;
    max-width: 450px;
  }
}

.save.v-btn {
  margin-left: 300px;
  padding: 0 32px !important;
}

h1 {
  color: $primary-font-color;
  font-size: 2rem;
}

.description {
  color: $grey-50;
  font-size: 1.13rem;
}

.circular-progress {
  height: 100%;
}
</style>
