<template>
  <v-dialog
    v-hotkey="{ esc: close }"
    @input="close"
    :value="show"
    :max-width="maxWidth"
    :eager="eager"
    :persistent="persistent"
    :content-class="`dialog-content-container ${contentClass}`"
    scrollable>
    <v-card :class="[{ outlined }, padding]" :loading="loading ? 'primary' : false" shaped>
      <back-button @click="close" :disabled="disableClose" />
      <v-card-title>
        <slot name="header"></slot>
      </v-card-title>
      <slot></slot>
    </v-card>
  </v-dialog>
</template>

<script>
import BackButton from './BackButton';

export default {
  name: 'cepp-dialog',
  props: {
    loading: { type: Boolean, default: false },
    show: { type: Boolean, default: false },
    maxWidth: { type: String, default: '43.75rem' },
    eager: { type: Boolean, default: false },
    persistent: { type: Boolean, default: true },
    outlined: { type: Boolean, default: false },
    disableClose: { type: Boolean, default: false },
    contentClass: { type: String, default: '' },
    padding: { type: String, default: 'pa-12' }
  },
  methods: {
    close() {
      if (!this.disableClose) this.$emit('update:show', false);
    }
  },
  components: { BackButton }
};
</script>

<style lang="scss">
.dialog-content-container {
  @include z-index(dialog);

  border-radius: 0 !important;
  box-shadow: 0;

  .outlined {
    border: 2px solid $primary-color;
    box-shadow: none;
  }

  .v-card {
    box-shadow: none;
    border-radius: 0 !important;

    &__progress {
      .v-progress-linear--absolute {
        top: 0;
      }
    }
  }
}

</style>
