<template>
  <cepp-dialog
    v-on="$listeners"
    @update:show="close"
    v-bind="$attrs"
    :show="show"
    content-class="avatar-dialog"
    eager outlined
    max-width="650">
    <croppa
      v-model="croppa"
      @file-choose="hasImage = true"
      @file-size-exceed="showSizeError"
      @new-image-drawn="onNewImage"
      @draw="onZoom"
      :width="340"
      :height="340"
      :file-size-limit="maxSizeInBytes"
      :placeholder-font-size="22"
      :show-remove-button="false"
      :image-border-radius="100"
      placeholder="Click to select an image."
      accept=".jpeg,.jpg,.png"
      replace-drop
      prevent-white-space
      class="upload align-self-center mb-3 rounded-circle" />
    <template v-if="hasImage">
      <div v-if="!error">
        <v-slider
          v-model="zoom"
          @start="onSliderDrag"
          @end="onSliderDrag"
          @click="onSliderClick"
          :min="zoomMin"
          :max="zoomMax"
          step="0.1"
          color="info"
          :thumb-size="48"
          track-color="info">
          <template #prepend>
            <span @click="zoomOut"><cepp-icon icon="minus" color="grey" /></span>
          </template>
          <template #append>
            <span @click="zoomIn"><cepp-icon icon="plus" color="grey" /></span>
          </template>
        </v-slider>
        <p class="instructions font-italic text-center d-flex align-center">
          <cepp-icon size="16" color="black" class="mr-2" icon="cursor-move" fill />
          <span>Click and drag to reposition.</span>
        </p>
      </div>
      <p v-else class="text-center red--text">{{ error }}</p>
      <cepp-btn
        @click="upload"
        :loading="uploading"
        :disabled="uploading || error"
        color="accent"
        type="submit"
        class="save ma-auto">
        Upload
      </cepp-btn>
    </template>
    <p v-if="!hasImage" class="size-notice">The maximum file size is 2MB.</p>
  </cepp-dialog>
</template>

<script>
import 'vue-croppa/dist/vue-croppa.css';
import CeppDialog from '@/components/Dialog';
import { component as Croppa } from 'vue-croppa';

export default {
  nama: 'avatar-dialog',
  props: {
    show: { type: Boolean, default: false },
    avatar: { type: [File, Blob], default: null },
    removed: { type: Boolean, default: false },
    uploading: { type: Boolean, default: true }
  },
  data: () => ({
    croppa: {},
    error: null,
    maxSizeInBytes: 2000000, // 2MB
    zoom: 0,
    zoomMin: 0,
    zoomMax: 0,
    hasImage: false
  }),
  methods: {
    close() {
      this.hasImage = false;
      this.croppa.refresh();
      this.$emit('update:show', false);
    },
    upload() {
      this.croppa.generateBlob(blob => this.$emit('upload-avatar', blob));
    },
    openFileSelection() {
      this.croppa.chooseFile();
    },
    showSizeError() {
      this.error = 'We’re sorry, that file is too big. Maximum size is 2MB.';
    },
    zoomOut() {
      this.zoom = this.zoom - 0.1;
      this.croppa.scaleRatio = this.zoom;
    },
    zoomIn() {
      this.zoom = this.zoom + 0.1;
      this.croppa.scaleRatio = this.zoom;
    },
    onSliderDrag(value) {
      this.zoom = value;
      this.croppa.scaleRatio = value;
    },
    onSliderClick(e, data) {
      if (e.target.firstElementChild) {
        this.zoom = Number(e.target.firstElementChild.value);
        this.croppa.scaleRatio = Number(e.target.firstElementChild.value);
      }
    },
    onNewImage() {
      this.error = null;
      this.hasImage = true;
      this.zoomMin = this.croppa.scaleRatio;
      this.zoomMax = this.croppa.scaleRatio * 3;
    },
    onZoom() {
      if (this.zoomMax && this.croppa.scaleRatio >= this.zoomMax) {
        this.croppa.scaleRatio = this.zoomMax;
      } else if (this.zoomMin && this.croppa.scaleRatio <= this.zoomMin) {
        this.croppa.scaleRatio = this.zoomMin;
      }
      this.zoom = this.croppa.scaleRatio;
    }
  },
  watch: {
    show(val) {
      if (val) {
        this.hasImage = false;
        this.zoom = 0;
        this.croppa.refresh();
      }
    }
  },
  components: {
    CeppDialog,
    Croppa
  }
};
</script>

<style lang="scss" scoped>
.upload {
  canvas {
    border-radius: 50%;
  }
}

.save {
  padding: 0 32px !important;
}

.size-notice {
  text-align: center;
  margin-top: 0.25rem;
  color: $grey-50
}

.instructions {
  position: absolute;
  bottom: 25%;
  left: 30%;
  margin: 0;
  padding: 3px 15px;
  background: $white;
  border: 1px solid #979797;
  cursor: default;
  user-select: none;
  border-radius: 30px !important;
}

.croppa-container {
  ::v-deep canvas {
    border-radius: 50%;
  }
}

::v-deep {
  .v-slider {
    &__thumb.info {
      width: 21px;
      height: 21px;
      background-color: $white !important;
      border: 1px solid !important;
      border-color: #979797 !important;

      &::before {
        background-color: $white !important;
        opacity: 0;
      }
    }
  }
}
</style>

<style lang="scss">
.avatar-dialog.v-dialog--scrollable > .v-card {
  height: 100%;
}
</style>
