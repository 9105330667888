import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { max, min, required } from 'vee-validate/dist/rules';
import { messages } from 'vee-validate/dist/locale/en.json';

extend('names', {
  message: 'The {_field_} is not valid',
  validate: value => /^((?:[\u00c0-\u01ffa-zA-Z'-.\d])+\s?)+$/.test(value)
});

extend('zipcode', {
  validate(value) {
    const isNumber = /^\d+$/.test(value);
    return value.length === 5 && isNumber;
  },
  message: 'Zip code is not valid.'
});

const VeeValidate = {
  install(Vue) {
    extend('max', { ...max, message: messages.max });
    extend('min', { ...min, message: messages.min });
    extend('required', { ...required, message: 'Please fill out this field.' });
    Vue.component('validation-provider', ValidationProvider);
    Vue.component('validation-observer', ValidationObserver);
  }
};
export default VeeValidate;
