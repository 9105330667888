import axios from 'axios';

const { SERVER_URL } = process.env;
const apiPath = process.env.API_PATH || '/api/v1/';
const url = new URL(apiPath, SERVER_URL);
const config = {
  baseURL: url.href,
  withCredentials: true,
  headers: {
    'Access-Control-Allow-Origin': window.location.origin
  }
};

// Instance of axios to be used for all API requests.
const client = axios.create(config);

Object.defineProperty(client, 'base', {
  get() {
    if (!this.base_) this.base_ = axios.create(config);
    return this.base_;
  }
});

export default client;
