<template>
  <component
    :is="icon"
    ref="ceppIcon"
    :width="size"
    :height="size"
    :class="{ fill }"
    class="svg-icon" />
</template>

<script>
import Icons from '@/icons';
import { pick } from 'lodash-es';

export default {
  name: 'cepp-icon',
  extends: Icons,
  props: {
    icon: { type: String, required: true },
    size: { type: String, default: '22' },
    fill: { type: Boolean, default: false },
    color: { type: String, default: null }
  },
  computed: {
    themeColors: ({ $vuetify }) => pick($vuetify.theme.themes.light, [
      'primary', 'secondary', 'accent', 'highlight', 'error', 'attention'
    ])
  },
  methods: {
    setColor(value) {
      const color = this.themeColors[value] || value;
      this.$refs.ceppIcon.style.setProperty('--color', color);
    }
  },
  watch: {
    color: 'setColor'
  },
  mounted() {
    if (this.color) this.setColor(this.color);
  }
};
</script>

<style lang="scss" scoped>
.svg-icon {
  display: inline-block;
  vertical-align: middle;
  color: var(--color);

  &.fill {
    path {
      fill: currentColor;
    }
  }
}
</style>
