import managementApi from '@/api/management';
import Vue from 'vue';

const state = Vue.observable({
  user: {}
});

export const getters = {
  user: () => state.user
};

export const mutations = {
  setUser: user => { state.user = user; }
};

export const actions = {
  fetchUser(email) {
    return managementApi.getUser(email)
      .then(user => mutations.setUser(user));
  },
  updateUser(data) {
    return managementApi.updateUser(data)
      .then(user => mutations.setUser(user));
  }
};
