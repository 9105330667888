<template>
  <v-menu
    v-if="user"
    v-model="showMenu"
    min-width="190px"
    transition="slide-y-transition"
    offset-y open-on-hover>
    <template #activator="{ on }">
      <div v-on="on" class="dropdown-activator">
        <v-btn icon>
          <avatar
            :avatar="user.picture"
            :first-name="user.given_name"
            :last-name="user.family_name" font-size="18" />
        </v-btn>
        <cepp-icon
          icon="chevron-down"
          size="24"
          fill
          :class="{ rotate: showMenu }"
          class="ml-1" />
      </div>
    </template>
    <v-list class="menu-list" offset-y>
      <v-list-item
        @click="changePassword"
        class="menu-item">
        Change Password
      </v-list-item>
      <v-list-item
        @click="$auth0.logout()"
        class="menu-item">
        Logout
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import Avatar from '@/components/UserProfile/Avatar';
import { getters } from '@/store';
import management from '@/api/management';

export default {
  name: 'nav-dropdown-menu',
  data: () => ({ showMenu: false }),
  computed: {
    user() {
      return getters.user();
    }
  },
  methods: {
    changePassword() {
      management.getPasswordChangeUrl().then(url => url && window.open(url));
    }
  },
  components: { Avatar }
};
</script>

<style lang="scss" scoped>
.dropdown-activator {
  cursor: pointer;

  .v-icon {
    color: $grey-10;

    &.rotate {
      transform: rotate(180deg);
      transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    }
  }
}

.menu-list {
  padding: 10px 0;
  font-size: 0.8125rem;
  border-radius: 0;
  text-transform: uppercase;

  .v-divider {
    border-color: $disabled-color;
    margin: 10px 0;
  }

  .menu-bottom {
    padding-top: 17px;
    border-top: 1px solid $disabled-color;
  }

  .menu-item { // stylelint-disable-line
    min-height: auto;
    padding: 5px 30px;
    background: none;
    transition: color 0.3s;

    &::before {
      background-color: transparent;
    }

    &:not(.menu-item--active):not(.menu-item--disabled) {
      color: $text-color !important;

      &:hover {
        color: $accent-color !important;
        background-color: transparent;
      }
    }
  }
}
</style>
