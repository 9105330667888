import request from '@/api/request';

const url = {
  me: '/management/me',
  changePassword: '/management/change-password'
};

function getUser(email) {
  return request(url.me, { params: { email } })
    .then(({ data }) => data);
}

function updateUser(params) {
  return request.patch(url.me, params).then(({ data }) => data);
}

function getPasswordChangeUrl() {
  return request.get(url.changePassword).then(({ data }) => data);
}

export default {
  getPasswordChangeUrl,
  getUser,
  updateUser
};
