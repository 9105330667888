<template>
  <cepp-dialog
    v-on="$listeners"
    @update:show="close"
    v-bind="$attrs"
    :show="show"
    eager outlined
    max-width="500"
    content-class="confirmation-dialog">
    <v-form @submit.prevent="executeAction">
      <v-card-title class="headline pb-2">{{ heading }}</v-card-title>
      <v-card-text class="font-italic pb-8 message">{{ message }}</v-card-text>
      <v-card-actions class="d-flex justify-space-between">
        <cepp-btn @click="close" color="accent" outlined>
          No, Keep Photo
        </cepp-btn>
        <cepp-btn :loading="isLoading" :disabled="isLoading" type="submit" color="accent">
          Yes, Remove Photo
        </cepp-btn>
      </v-card-actions>
    </v-form>
  </cepp-dialog>
</template>

<script>
import CeppDialog from '@/components/Dialog';
import { pDelay } from '@/utils';

export default {
  name: 'confirmation-dialog',
  props: {
    show: { type: Boolean, default: false },
    heading: { type: String, default: '' },
    message: { type: String, default: 'Are you sure?' },
    action: { type: Function, default: () => true }
  },
  data: () => ({ isLoading: false }),
  methods: {
    close() {
      this.$emit('update:show', false);
    },
    executeAction() {
      this.isLoading = true;
      return Promise.all([pDelay(700), Promise.resolve(this.action())])
        .then(() => { this.$emit('confirmed'); })
        .finally(() => {
          this.isLoading = false;
          this.close();
        });
    }
  },
  components: { CeppDialog }
};
</script>

<style lang="scss" scoped>
  ::v-deep {
    .confirmation-dialog {
      border-radius: 0;
      box-shadow: unset;

      .headline {
        font-size: 25px;
      }

      .message {
        color: $primary-font-color;
      }

      .v-sheet.v-card {
        border-radius: 0;
      }
    }
  }
</style>
