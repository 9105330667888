import ArrowLeftCircle from '@/icons/arrow-left-circle.svg';
import CheckCircleOutline from '@/icons/check-circle-outline.svg';
import ChevronDown from '@/icons/chevron-down.svg';
import ChevronLeft from '@/icons/chevron-left.svg';
import ChevronRight from '@/icons/chevron-right.svg';
import CursorMove from '@/icons/cursor-move.svg';
import Email from '@/icons/email.svg';
import Minus from '@/icons/minus.svg';
import Open from '@/icons/open.svg';
import Pencil from '@/icons/pencil.svg';
import Plus from '@/icons/plus.svg';
import TimesCircle from '@/icons/times-circle.svg';

export default {
  components: {
    ArrowLeftCircle,
    CheckCircleOutline,
    ChevronDown,
    ChevronLeft,
    ChevronRight,
    CursorMove,
    Email,
    Minus,
    Open,
    Pencil,
    Plus,
    TimesCircle
  }
};
