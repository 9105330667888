import { auth0Guard } from '@/plugins/auth0';
import Home from '@/components/index';
import Router from 'vue-router';
import UserProfile from '@/components/UserProfile';
import Vue from 'vue';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [{
    path: '/',
    component: Home,
    beforeEnter: auth0Guard,
    children: [{
      path: '',
      name: 'profile',
      component: UserProfile,
      props: route => ({ isFirstLogin: route.query.isFirstLogin === 'true' })
    }]
  }]
});

export default router;
