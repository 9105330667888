<template>
  <v-hover v-slot="{ hover }">
    <v-btn
      v-on="$listeners"
      v-bind="$attrs"
      :outlined="!!(outlined ^ hover)"
      rounded depressed>
      <span :class="{ 'pl-2': hasIcon }">
        <slot></slot>
      </span>
      <span :class="{ 'pl-4': hasIcon }" class="d-flex align-center">
        <slot name="icon" :hover-color="$attrs.color"></slot>
      </span>
    </v-btn>
  </v-hover>
</template>

<script>
export default {
  name: 'cepp-btn',
  props: {
    outlined: { type: Boolean, default: false }
  },
  computed: {
    hasIcon: ({ $slots }) => !!$slots.icon
  }
};
</script>

<style lang="scss" scoped>
@mixin disabled() {
  color: $white !important;
  background-color: $disabled-color !important;
  border-color: $disabled-color;
}

.v-btn {
  border-width: 2px;
  border-style: solid;
  letter-spacing: normal;
  transition: all 0.3s;

  &::before, &:focus::before, &:not(.v-btn--text):not(.v-btn--outlined):hover::before {
    opacity: 0;
  }

  ::v-deep {
    .v-btn__content {
      font-weight: 600;
    }

    .v-icon {
      transition: none;
    }
  }

  &.v-size--default {
    font-size: 1rem;
  }

  &.v-btn--disabled {
    @include disabled;

    ::v-deep .v-icon, .v-btn__loading {
      color: $white !important;
    }

    &:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
      @include disabled;
    }
  }

  &:not(.v-btn--round).v-size--default {
    height: 45px;
    padding: 0 24px;
  }
}
</style>
