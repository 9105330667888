<template>
  <footer class="footer">
    <div class="text-right">
      <a
        href="https://advancingpretrial.org/appr/help/"
        rel="noopener"
        target="_blank"
        class="contact-us d-inline-flex align-center">
        Contact Us
        <cepp-icon color="black" size="25" icon="email" class="contact-us-icon" fill />
      </a>
    </div>
    <div class="d-flex justify-space-between">
      <div>
        <p class="mission ma-0">
          APPR is supported by
          <a
            href="https://www.arnoldventures.org"
            rel="noopener"
            target="_blank"
            class="highlight-link">Arnold Ventures</a> and is a member of the
          <a
            href="https://www.pretrialpartnership.org"
            rel="noopener"
            target="_blank"
            class="highlight-link">National Partnership for Pretrial Justice</a>.
        </p>
        <div class="links d-flex items-center">
          <a
            href="https://advancingpretrial.org/terms"
            rel="noopener"
            target="_blank"
            class="link">
            Terms of Use
          </a>
          <v-divider
            class="divider m-2"
            inset
            vertical />
          <a
            href="https://advancingpretrial.org/privacy"
            rel="noopener"
            target="_blank"
            class="link">
            Privacy Policy
          </a>
        </div>
      </div>
      <p class="terms ma-0">
        &copy; {{ new Date().getFullYear() }} Laura and John Arnold Foundation, supported by
        <a
          href="https://www.arnoldventures.org"
          rel="noopener"
          target="_blank"
          class="highlight-link">
          Arnold Ventures</a>.
        Use of the Public Safety Assessment™ (PSA) is subject to applicable
        <a
          href="https://advancingpretrial.org/terms"
          rel="noopener"
          target="_blank"
          class="highlight-link">
          Terms of Use</a>,
        including compliance with the PSA Core Requirements.
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'cepp-footer'
};
</script>

<style lang="scss" scoped>
$mission-measure: 27.6875rem;
$terms-measure: 19.25rem;
$link-transition: color 0.3s;

.footer {
  width: 100%;
  padding: 1.875rem 4.625rem 4.8125rem 4.625rem;
  background: $grey-87;
}

.v-divider.divider {
  border-color: $black;
  height: 15px;
  margin: 2px 5px;
}

.contact-us {
  margin-bottom: 1.875rem;
  color: $text-color;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;
  letter-spacing: 0;
  text-decoration: none;
  transition: $link-transition;

  &-icon {
    margin-left: 15px;
    color: $text-color;
    transition: $link-transition;
  }

  &:hover, &:hover &-icon {
    color: $accent-color !important;
  }
}

.mission {
  max-width: $mission-measure;
  color: $label-color;
  font-size: 1rem;
  letter-spacing: 0;
  line-height: 1.5rem;
}

.terms {
  max-width: $terms-measure;
  text-align: right;
  color: $label-color;
  font-size: 0.75rem;
  letter-spacing: 0;
}

.highlight-link {
  color: $highlight-color;
  font-weight: 700;
  text-decoration: none;
}

.links {
  margin-top: 1.125rem;
  color: $text-color;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.125rem;
}

.link {
  color: currentColor;
  text-decoration: none;
  transition: $link-transition;

  &:hover {
    color: $accent-color;
  }
}
</style>
