<template>
  <validation-provider
    v-slot="{ errors }"
    ref="validator"
    mode="eager"
    :name="$attrs.name"
    :rules="rules"
    tag="div"
    class="d-flex field-wrapper align-baseline">
    <div>
      <v-text-field
        ref="textField"
        v-model="innerValue"
        v-on="$listeners"
        :disabled="disabled"
        v-bind="$attrs"
        class="m-0 p-0" />
      <p
        v-if="errors.length || showMessage"
        class="m-0 ml-3 p-0 error-message">
        <slot name="message">{{ errors[0] || errorMessage }}</slot>
      </p>
    </div>
    <span @click="enableEditing">
      <cepp-icon
        icon="pencil"
        size="18"
        :color="disabled ? '#b9b9b9' : 'attention'"
        class="ml-8 pencil" />
    </span>
  </validation-provider>
</template>

<script>
export default {
  name: 'cepp-text-field',
  props: {
    rules: { type: [Object, String], default: '' },
    value: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    validate: { type: Boolean, default: true },
    errorMessage: { type: String, default: null }
  },
  data: () => ({ showMessage: false }),
  computed: {
    innerValue: {
      get() { return this.value; },
      set(value) { this.$emit('input', value); }
    }
  },
  methods: {
    enableEditing() {
      this.validate
        ? this.$nextTick(() => this.$refs.textField.$el.querySelector('input').focus())
        : this.showMessage = !this.showMessage;
    },
    hideMessage() {
      this.showMessage = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.field-wrapper {
  max-width: 510px;
  max-height: 45px;
  margin-bottom: 45px;

  & > div {
    width: 100%;
  }
}

.error-message {
  color: $not-so-red;
  font-size: 15px;
  line-height: 18px;
  font-style: italic;
}

.pencil {
  cursor: pointer;
}

::v-deep {
  .error-message {
    a {
      color: $not-so-red;
      text-decoration: none;
    }
  }

  .v-input {
    &--is-disabled {
      & > .v-input__control > .v-input__slot fieldset {
        color: $grey-73 !important;
        background: rgba(241, 243, 244, 0.6);
      }
    }

    &.v-text-field--outlined {
      .v-input__control {
        .v-text-field__details {
          display: none;
        }

        .v-label:not(.v-label--active) {
          top: 13px;
        }

        .v-input__slot {
          min-height: 45px;

          fieldset {
            border: 1px solid $grey-73;
          }
        }
      }
    }
  }
}
</style>
