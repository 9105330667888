<template>
  <div class="d-flex justify-center align-center flex-column">
    <cepp-avatar
      :avatar="avatar || profile.picture"
      :first-name="profile.given_name"
      :last-name="profile.family_name"
      size="147"
      font-size="50"
      color="info"
      class="align-self-center mb-4" />
    <cepp-btn
      @click="openAvatarDialog"
      color="accent"
      class="mx-8 mb-3 upload-button">
      Upload photo
    </cepp-btn>
    <v-btn
      v-show="showRemoveButton"
      @click="isRemoveConfirmOpen = true"
      color="secondary"
      text
      class="remove-button mx-8">
      Remove photo
    </v-btn>
    <confirmation-dialog
      :action="removeAvatar"
      :show.sync="isRemoveConfirmOpen"
      heading="Remove photo permanently?"
      message="If you remove this photo, you won't be able to recover it." />
    <avatar-dialog
      @upload-avatar="uploadAvatar"
      @close="avatar = null"
      :uploading="uploading"
      :show.sync="showAvatarDialog"
      :avatar="avatar" />
  </div>
</template>

<script>
import { actions, mutations } from '@/store';
import AvatarDialog from './AvatarDialog';
import CeppAvatar from './Avatar';
import ConfirmationDialog from '@/components/ConfirmationDialog';
import { serialize } from 'object-to-formdata';

export default {
  name: 'user-profile-sidebar',
  props: {
    profile: { type: Object, required: true }
  },
  data: () => ({
    isRemoveConfirmOpen: false,
    showAvatarDialog: false,
    avatar: null,
    uploading: false
  }),
  computed: {
    showRemoveButton: ({ profile, avatar }) => {
      if (avatar) return true;
      const { picture } = profile;
      return picture && !(picture.includes('ui-avatars') || picture.includes('gravatar'));
    }
  },
  methods: {
    ...mutations,
    save(data) {
      this.uploading = true;
      return actions.updateUser(data)
        .then(() => {
          this.showAvatarDialog = false;
          this.uploading = false;
        });
    },
    openAvatarDialog() {
      this.showAvatarDialog = true;
    },
    uploadAvatar(avatar) {
      this.avatar = avatar;
      // eslint-disable-next-line camelcase
      const { user_id, picture } = this.profile;
      const data = serialize({ user_id, picture });
      if (this.avatar) data.append('file', this.avatar, this.avatar.name);
      return this.save(data);
    },
    removeAvatar() {
      this.isRemoveConfirmOpen = false;
      this.avatar = null;
      return this.save({ user_id: this.profile.user_id, picture: null });
    }
  },
  components: {
    AvatarDialog,
    CeppAvatar,
    ConfirmationDialog
  }
};
</script>

<style lang="scss" scoped>
.remove-button {
  letter-spacing: normal;
  text-transform: capitalize;
}

.remove-button,.upload-button {
  max-width: 170px;
}
</style>
