<template>
  <v-avatar :size="size">
    <img :src="imageSrc" :alt="`${firstName} ${lastName} avatar`">
    <slot></slot>
  </v-avatar>
</template>

<script>
const UI_AVATAR_API = 'https://eu.ui-avatars.com/api/?background=F0BE18&color=fff&name=';

export default {
  name: 'cepp-avatar',
  props: {
    avatar: { type: [String, File, Blob], default: '' },
    firstName: { type: String, default: '' },
    lastName: { type: String, default: '' },
    size: { type: String, default: '42' }
  },
  data: () => ({ imageSrc: '' }),
  computed: {
    uiAvatar: ({ firstName, lastName }) =>
      `${UI_AVATAR_API}${encodeURIComponent(`${firstName} ${lastName}`)}`
  },
  watch: {
    avatar: {
      handler() {
        const { avatar, uiAvatar } = this;
        if (typeof avatar === 'string') {
          this.imageSrc = avatar.includes('ui-avatars') || avatar.includes('gravatar')
            ? uiAvatar
            : avatar;
          return;
        }
        if (avatar instanceof File || avatar instanceof Blob) {
          this.imageSrc = URL.createObjectURL(avatar);
          return;
        }

        this.imageSrc = uiAvatar;
      },
      immediate: true
    }
  }
};
</script>

<style lang="scss" scoped>
.v-avatar {
  position: relative;

  img {
    object-fit: cover;
  }
}
</style>
