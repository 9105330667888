import { actions } from '@/store';
import createAuth0Client from '@auth0/auth0-spa-js';
import request from '@/api/request';
import Vue from 'vue';

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

const useAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = window.location.origin,
  ...options
}) => new Vue({
  data: () => ({
    loading: true,
    isAuthenticated: false,
    user: {},
    auth0Client: null,
    error: null
  }),
  methods: {
    loginWithRedirect(options) {
      return this.auth0Client.loginWithRedirect(options);
    },
    getIdTokenClaims(options) {
      return this.auth0Client.getIdTokenClaims(options);
    },
    getTokenSilently(options) {
      return this.auth0Client.getTokenSilently(options);
    },
    logout() {
      return this.auth0Client.logout({ returnTo: redirectUri });
    },
    async setAppState() {
      const { search } = window.location;
      if (!search.includes('code=') || !search.includes('state=')) return;
      const { appState } = await this.auth0Client.handleRedirectCallback();
      this.error = null;
      onRedirectCallback(appState);
    },
    async getUser() {
      this.isAuthenticated = await this.auth0Client.isAuthenticated();
      if (this.isAuthenticated) {
        this.user = await this.auth0Client.getUser();
        const accessToken = await this.auth0Client.getIdTokenClaims();
        request.defaults.headers = { Authorization: `Bearer ${accessToken.__raw}` };
        await actions.fetchUser(this.user.email);
      }
      this.loading = false;
    }
  },
  created() {
    return createAuth0Client({
      domain: options.domain,
      client_id: options.clientId,
      audience: options.audience,
      redirect_uri: redirectUri
    })
    .then(auth0Client => {
      this.auth0Client = auth0Client;
    })
    .then(() => this.setAppState())
    .catch(e => { this.error = e; })
    .finally(() => this.getUser());
  }
});

export const Auth0Plugin = {
  install(Vue, options) {
    Vue.prototype.$auth0 = Vue.$auth0 = useAuth0(options);
  }
};

export const auth0Guard = (to, _, next) => {
  const login = () => {
    if (Vue.$auth0.isAuthenticated) return next();
    Vue.$auth0.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
  };

  if (!Vue.$auth0.loading) return login();
  Vue.$auth0.$watch('loading', loading => !loading ? login() : undefined);
};
